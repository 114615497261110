<template>
  <div class="questionList">
    <div class="header">
      <div class="header-box">
        <i class="el-icon-back" @click="goBack"></i>
        {{ viewData.name }}
      </div>
    </div>
    <div v-if="questionList.length == 0" v-loading="loading" class="null-box">
      <img src="@/static/resources/none.svg" alt="" />
      <p>当前试卷未找到试题</p>
    </div>
    <div class="question-box">
      <template v-for="(item, index) in questionList">
        <div :key="index">
          <div class="type-name">{{ item.title }}</div>
          <template v-for="(v, i) in item.questionList">
            <div :key="i" class="question-list">
              <p style="padding: 0 12px">{{ v.source }}</p>
              <div style="padding: 0 12px 12px" v-html="v.question"></div>
              <div class="btm-box">
                <div class="btm-left-box">
                  <!-- <span v-if="v.countUse">点击：{{ v.countUse }}</span> -->
                  <span>{{ v.difficulty | setName(difficultyListCopy) }}</span>
                  <!-- <span>来源：{{ v.source ? v.source : "未标注" }}</span> -->
                </div>
                <div class="btn-box">
                  <!-- <span>收藏</span> -->
                  <span @click="show(v)">解析</span>
                  <span @click="situation(v)">考情</span>
                  <el-button
                    type="primary"
                    class="btn-all"
                    @click="showCheck(v)"
                  >
                    换题
                    <i class="el-icon-refresh el-icon--right"></i>
                  </el-button>
                </div>
              </div>
              <!-- 考情 -->
              <div
                v-if="situationArr.indexOf(v.id) != -1"
                class="situation-box"
              >
                <template
                  v-if="
                    v.situation && v.situation.anyExamUseQuestion.length == 0
                  "
                >
                  数据尚未同步
                </template>
                <template v-else>
                  <p>
                    <span class="situation-box-name">考情：</span>本题共
                    <span>{{ v.situation.countReply }}</span> 人使用,共{{
                      v.situation.examNum
                    }}场考试
                  </p>
                  <div
                    v-for="(m, n) in v.situation.anyExamUseQuestion"
                    :key="n"
                    class="situation-list"
                  >
                    <div class="situation-list-left">
                      <p>{{ m.examName }}</p>
                      <div>
                        <span class="situation-list-name">{{
                          m.examDate
                        }}</span>
                        <span> | </span>
                        <span class="situation-list-name"
                          >共{{ m.countReply }}人参考</span
                        >
                        <span> | </span>
                        <span class="situation-list-name">{{
                          m.schoolLevel
                        }}</span>
                      </div>
                    </div>
                    <div class="situation-list-right">
                      {{ m.scoreRate | setNumber }}
                    </div>
                  </div>
                </template>
              </div>
              <div v-if="showListArr.indexOf(v.id) != -1" class="analysis-box">
                <div v-if="v.newQuestion" class="analysis-item">
                  【同类题】
                  <div v-html="v.newQuestion"></div>
                </div>
                <div class="know-box analysis-item">
                  【知识点】
                  <template v-if="v.knowledgeNames">
                    <div v-for="(m, n) in v.knowledgeNames.split('#')" :key="n">
                      {{ m }}
                    </div>
                  </template>
                </div>

                <div class="analysis-item">
                  【答案】
                  <div v-html="v.answer"></div>
                </div>
                <div class="analysis-item">
                  【解析】
                  <div v-html="v.analysis"></div>
                </div>
                <div class="analysis-item">
                  【分析】
                  <div v-html="v.ability"></div>
                </div>
                <!-- <div class="id-box">学科id：{{ v.xuekeId }}</div> -->
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div v-if="!loading" class="btn-link-box">
      <el-button type="primary" class="btn-all" @click="showPaperList"
        >试卷预览</el-button
      >
    </div>
    <checkQuestion
      :key="key"
      :index-obj="indexObj"
      @onLoadList="onLoadList"
    ></checkQuestion>

    <!-- 未找到试题的弹出层 -->
    <el-dialog title="未找到试题列表" :visible.sync="dialogVisible" width="60%">
      <div>
        <el-table :data="typeList" border style="width: 100%">
          <el-table-column
            prop="questionId"
            label="题号"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="questionTypeName"
            label="题型"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="knowledgeNames"
            label="知识点"
            align="center"
            width="530"
          >
            <template slot-scope="scope">
              <div class="know-model-box">
                <div
                  v-for="(v, i) in scope.row.knowledgeNames"
                  :key="i"
                  class="know-list"
                >
                  {{ v }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="difficulty" label="难度" align="center">
            <template slot-scope="scope">
              {{ scope.row.difficulty | setName(difficultyListCopy) }}
            </template>
          </el-table-column>
          <el-table-column prop="score" label="分值" align="center">
            <template slot-scope="scope">
              {{ scope.row.score }}
              <!-- <el-input-number
                v-model="scope.row.score"
                :min="0"
              ></el-input-number> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//
import { difficultyListCopy } from "@/core/util/constdata";
import { situation, answer } from "@/core/api/resource/knowledgePointsPaper";
import {
  getconditionexampaper,
  getExampapercondition,
  getExampaperplan,
} from "@/core/api/resource/breakdownPaper";

import checkQuestion from "@/views/resource/breakdownPaper/components/checkQuestion";
export default {
  name: "QuestionList",
  components: {
    checkQuestion,
  },
  data() {
    return {
      dialogVisible: false,
      difficultyListCopy: difficultyListCopy,
      showListArr: [],
      situationArr: [],
      viewData: {},
      questionList: [],
      typeNameList: [],
      nullData: [],
      indexObj: {},
      key: 1,
      typeList: [],
      allTypeList: [],
      loading: true,
    };
  },
  created() {
    this.initData();
    this.getList();
  },
  methods: {
    showPaperList() {
      // console.log(this.allTypeList);
      if (
        this.allTypeList.length != 0 &&
        this.allTypeList.length == this.nullData.length
      ) {
        this.$message({
          message: "当前试卷没有试题",
          type: "warning",
        });
        return;
      }
      this.$router.push({
        name: "PaperPreview",
        query: {
          id: this.planId,
        },
      });
    },
    getInitData() {
      getExampapercondition(this.viewData.id)
        .then((res) => {
          this.loading = false;
          let submitData = res.data.data;
          this.typeList = JSON.parse(submitData.questionConditionList);
          this.typeList.map((index) => {
            index.questionId = Number(index.questionId);
          });
          this.allTypeList = JSON.parse(JSON.stringify(this.typeList));
          // console.log(this.allTypeList);
          this.typeList = this.typeList.filter(
            (item) => this.nullData.indexOf(item.questionId) != -1
          );
          this.dialogVisible = true;

          // console.log(this.typeList);

          this.typeList.map((index) => {
            index.knowledgeNames = index.knowledgeNames.split("#");
            index.knowledgeIds = index.knowledgeIds.split("#");
          });
          this.typeList = JSON.parse(JSON.stringify(this.typeList));
          //   if (this.typeList && this.typeList.length == 0) {
          //   return;
          // }
          // console.log(this.typeList);
          // console.log(this.nullData);

          if (this.allTypeList.length == this.nullData.length) {
            return;
          }
          // 调用接口初始化页面列表
          this.getExampaperplan();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //子组件调用  重新更新父组件
    onLoadList() {
      this.getExampaperplan();
    },
    showCheck(v) {
      this.indexObj = v;
      this.key = new Date().getTime();
      // console.log(v);
      // console.log(this.key);
    },
    // 切换是否显示页面列表
    show(v) {
      if (this.showListArr.indexOf(v.id) != -1) {
        this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
        return;
      }
      answer(v.questionId).then((res) => {
        // console.log(res);
        if (res.data.data.id) {
          v.answer = res.data.data.answer;
          v.analyse = res.data.data.analyse;
          v.analysis = res.data.data.analysis;
          v.ability = res.data.data.ability;
          v.knowledgeNames = res.data.data.knowledgeNames;
          if (res.data.data.id != v.questionId) {
            v.newQuestion = res.data.data.question;
          }
          if (this.showListArr.indexOf(v.id) == -1) {
            this.showListArr.push(v.id);
          } else {
            this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
          }
        } else {
          this.$message({
            message: "未找到同类题和解析",
            type: "warning",
          });
        }
      });
    },
    //获取考情
    situation(v) {
      if (v.situation) {
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
        return;
      }
      // console.log();
      situation(v.id).then((res) => {
        v.situation = res.data.data;
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
        // console.log(res);
      });
    },

    initData() {
      let Base64 = require("js-base64").Base64;
      this.viewData = JSON.parse(Base64.decode(this.$route.query.code));
      // 设置难度列表
      this.difficultyListCopy = [{ label: "不限", value: 0 }].concat(
        this.difficultyListCopy
      );
    },
    getExampaperplan() {
      getExampaperplan(this.planId)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          this.questionList = res.data.data.questionTypeList;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getList() {
      getconditionexampaper(this.viewData.id)
        .then((res) => {
          this.nullData = res.data.data.nullData;
          let arr = [];
          this.nullData.forEach((index) => {
            arr.push(Number(index));
          });
          this.nullData = arr;
          this.planId = res.data.data.planId;
          // this.questionList = res.data.data.questionList;
          // 判断下是否可以调用试题列表接口
          if (this.nullData.length == 0) {
            this.getExampaperplan();
          } else {
            this.getInitData();
          }

          // this.typeNameList = [];
          // this.questionList.forEach(index => {
          //   if (this.typeNameList.indexOf(index.questionTypeName) == -1) {
          //     this.typeNameList.push(index.questionTypeName);
          //   }
          // });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$router.push({
        name: "BreakdownPaper",
        query: { id: this.viewData.id },
      });
    },
  },
};
</script>
<style lang="scss">
.questionList {
  .know-model-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .know-list {
      margin: 5px;
      color: #373737;
      // background-color: #8ce4cc;
      padding: 0 10px;
      height: 32px;
      line-height: 30px;
      font-size: 12px;
      // color: #409eff;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid #dddddd;
      // cursor: pointer;
      // margin-bottom: 8px;
    }
  }
  img {
    max-width: 100%;
  }
}
</style>
<style scoped lang="scss">
.questionList {
  margin-top: 50px;
  .null-box {
    width: 1220px;
    margin: 20px auto;
    img {
      width: 400px;
      display: block;
      margin: 80px auto 20px;
    }
    p {
      text-align: center;
      color: #999999;
    }
  }
  .btn-link-box {
    margin: 40px auto;

    text-align: center;
  }
  .question-box {
    width: 1220px;
    margin: 20px auto;
    // margin:  0;
    .type-name {
      color: $primary-color;
      margin-bottom: 12px;
    }
    .situation-box {
      padding: 12px;
      p {
        color: #606266;
      }
      .situation-box-name {
        color: $primary-color;
      }

      .situation-list {
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #e5e5e5;
        margin-bottom: 12px;
        .situation-list-left {
          p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
          .situation-list-name {
            color: #2196f3;
          }
        }
        .situation-list-right {
          color: #2196f3;
        }
      }
    }
    .analysis-box {
      .know-box {
        display: flex;
        align-items: center;
        div {
          color: #262626;
          padding: 0 5px;
          border-radius: 3px;
          margin-left: 12px;
          background: #e6f8f6;
        }
      }
    }
    .analysis-item {
      border-bottom: 1px dashed #dddddd;
      padding: 12px;
      &:last-child {
        border-bottom: none;
      }
    }
    .id-box {
      padding: 12px;
    }
    .btm-box {
      width: 100%;
      // position: absolute;
      // bottom: 0;
      // left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      padding-left: 24px;
      background: #f5f5f5;
      .btm-left-box {
        span {
          padding-right: 6px;
        }
      }
      .el-button {
        height: 40px;
        border-radius: 0 !important;
        width: 140px;
      }
      .btn-box {
        > span {
          padding-right: 12px;
          cursor: pointer;
        }
      }
      p {
        color: $primary-color;
        margin-bottom: 0;
      }
    }
    .question-list {
      width: 100%;
      border-radius: 4px;
      margin-bottom: 20px;
      border: 1px solid $primary-color;
      padding-top: 12px;
      overflow: hidden;
      position: relative;
    }
  }
  .header {
    border-bottom: 1px solid #dddddd;
    padding: 12px 0;
    .header-box {
      width: 1220px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .el-icon-back {
        margin-right: 12px;
        font-size: 14px;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
</style>
