<template>
  <div class="checkQuestion">
    <el-dialog title="替换试题" :visible.sync="dialogVisible" width="50%">
      <div class="top-btn">
        <span @click="newList"><i class="el-icon-refresh"></i>换一批</span>
      </div>
      <div class="know-box analysis-item">
        【知识点】
        <template v-if="indexObj.knowledgeNames">
          <div v-for="(m, n) in indexObj.knowledgeNames.split('#')" :key="n">
            {{ m }}
          </div>
        </template>
      </div>
      <div class="question-box">
        <div v-for="(v, i) in questionList" :key="i" class="question-list">
          <p style="padding: 0 12px">{{ v.source }}</p>
          <div style="padding: 0 12px 12px" v-html="v.question"></div>
          <div class="btm-box">
            <div class="btm-left-box">
              <!-- <span>使用：{{ v.countUse }}</span> -->
              <span>{{ v.difficulty | setName(difficultyListCopy) }}</span>
              <!-- <span>来源：{{ v.source ? v.source : "未标注" }}</span> -->
            </div>
            <div class="btn-box">
              <!-- <span>收藏</span> -->
              <span @click="show(v)">解析</span>
              <span @click="situation(v)">考情</span>
              <el-button
                type="primary"
                class="btn-all"
                @click="getreplacequestions(v)"
              >
                替换
                <i class="el-icon-refresh el-icon--right"></i>
              </el-button>
            </div>
          </div>
          <!-- 考情 -->
          <div v-if="situationArr.indexOf(v.id) != -1" class="situation-box">
            <template
              v-if="v.situation && v.situation.anyExamUseQuestion.length == 0"
            >
              数据尚未同步
            </template>
            <template v-else>
              <p>
                <span class="situation-box-name">考情：</span>本题共
                <span>{{ v.situation.countReply }}</span> 人使用,共{{
                  v.situation.examNum
                }}场考试
              </p>
              <div
                v-for="(m, n) in v.situation.anyExamUseQuestion"
                :key="n"
                class="situation-list"
              >
                <div class="situation-list-left">
                  <p>{{ m.examName }}</p>
                  <div>
                    <span class="situation-list-name">{{ m.examDate }}</span>
                    <span> | </span>
                    <span class="situation-list-name"
                      >共{{ m.countReply }}人参考</span
                    >
                    <span> | </span>
                    <span class="situation-list-name">{{ m.schoolLevel }}</span>
                  </div>
                </div>
                <div class="situation-list-right">
                  {{ m.scoreRate | setNumber }}
                </div>
              </div>
            </template>
          </div>
          <div v-if="showListArr.indexOf(v.id) != -1" class="analysis-box">
            <div class="know-box analysis-item">
              【知识点】
              <template v-if="v.knowledgeNames">
                <div v-for="(m, n) in v.knowledgeNames.split('#')" :key="n">
                  {{ m }}
                </div>
              </template>
            </div>
            <div class="analysis-item">
              【答案】
              <div v-html="v.answer"></div>
            </div>
            <div class="analysis-item">
              【解析】
              <div v-html="v.analysis"></div>
            </div>
            <div class="analysis-item">
              【分析】
              <div v-html="v.ability"></div>
            </div>
            <!-- <div class="id-box">学科id：{{ v.xuekeId }}</div> -->
          </div>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { difficultyListCopy } from "@/core/util/constdata";
import { situation, answer } from "@/core/api/resource/knowledgePointsPaper";
import {
  getreplacequestions,
  searchTraining,
} from "@/core/api/resource/breakdownPaper";
// import { searchTraining } from "@/core/api/exam/examLore";

export default {
  name: "CheckQuestion",
  props: {
    indexObj: Object,
  },
  data() {
    return {
      difficultyListCopy: difficultyListCopy,
      dialogVisible: false,
      pageNum: 1,
      viewData: {},
      examBstData: [],
      situationArr: [],
      showListArr: [],
      questionList: [],
      pageIndex: 1,
    };
  },
  created() {
    this.pageIndex = 1;
    this.init();
  },
  methods: {
    getreplacequestions(v) {
      let data = {
        planId: this.indexObj.planId,
        examQuestionId: this.indexObj.questionId,
        examQuestionIdReplace: v.id,
        checkType: 1,
      };

      getreplacequestions(data).then(() => {
        // console.log(res);
        this.$emit("onLoadList");
        this.dialogVisible = false;
      });
    },
    // 切换是否显示页面列表
    show(v) {
      answer(v.id).then((res) => {
        // console.log(res);
        if (res.data.data.id) {
          v.answer = res.data.data.answer;
          v.analyse = res.data.data.analyse;
          v.analysis = res.data.data.analysis;
          v.ability = res.data.data.ability;
          v.knowledgeNames = res.data.data.knowledgeNames;
          if (res.data.data.id != v.id) {
            v.newQuestion = res.data.data.question;
          }
          if (this.showListArr.indexOf(v.id) == -1) {
            this.showListArr.push(v.id);
          } else {
            this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
          }
        } else {
          this.$message({
            message: "未找到同类题和解析",
            type: "warning",
          });
        }
      });
    },
    //获取考情
    situation(v) {
      if (v.situation) {
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
        return;
      }
      situation(v.id).then((res) => {
        v.situation = res.data.data;
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
        // console.log(res);
      });
    },
    newList() {
      this.pageIndex++;
      this.searchTraining();
    },
    searchTraining() {
      let data = {
        knowledgeId: this.indexObj.knowledgeIds,
        quesType: this.indexObj.type,
        pageSize: 5,
        pageIndex: this.pageIndex,
      };
      searchTraining(this.indexObj.questionId, data).then((res) => {
        // console.log(res);
        this.questionList = res.data.data;
        setTimeout(() => {
          this.dialogVisible = true;
        }, 200);
      });
    },
    init() {
      // console.log(this.indexObj);
      if (!this.indexObj.id) {
        return;
      }
      this.searchTraining();

      // let Base64 = require("js-base64").Base64;
      // this.viewData = JSON.parse(Base64.decode(this.$route.query.code));
      // 设置难度列表
      this.difficultyListCopy = [{ label: "不限", value: 0 }].concat(
        this.difficultyListCopy
      );
    },
  },
};
</script>
<style lang="scss">
.checkQuestion {
  .el-dialog__body {
    height: calc(100vh - 250px);
    overflow-y: scroll;
    padding: 20px;
  }
  .top-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
    color: $primary-color;
    align-items: center;
    cursor: pointer;
  }
  .know-box {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    flex-wrap: wrap;
    div {
      color: #262626;
      padding: 0 5px;
      border-radius: 3px;
      margin-left: 12px;
      background: #e6f8f6;
    }
  }
}
</style>
<style scoped lang="scss">
.question-box {
  .situation-box {
    padding: 12px;
    p {
      color: #e5e5e5;
    }
    .situation-box-name {
      color: $primary-color;
    }

    .situation-list {
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #e5e5e5;
      margin-bottom: 12px;
      .situation-list-left {
        p {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
        .situation-list-name {
          color: #2196f3;
        }
      }
      .situation-list-right {
        color: #2196f3;
      }
    }
  }
  .analysis-box {
    .know-box {
      display: flex;
      align-items: center;
      div {
        color: #262626;
        padding: 0 5px;
        border-radius: 3px;
        margin-left: 12px;
        background: #e6f8f6;
      }
    }
  }
  .analysis-item {
    border-bottom: 1px dashed #dddddd;
    padding: 12px;
    &:last-child {
      border-bottom: none;
    }
  }
  .id-box {
    padding: 12px;
  }
  .btm-box {
    width: 100%;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding-left: 24px;
    background: #f5f5f5;
    .btm-left-box {
      span {
        padding-right: 6px;
      }
    }
    .el-button {
      height: 40px;
      border-radius: 0 !important;
      width: 140px;
    }
    .btn-box {
      > span {
        padding-right: 12px;
        cursor: pointer;
      }
    }
    p {
      color: $primary-color;
      margin-bottom: 0;
    }
  }
  .question-list {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid $primary-color;
    padding-top: 12px;
    overflow: hidden;
    position: relative;
  }
}
</style>
